import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


const Caption = ({ project }) => {

    return (
        <Container>
            <Row className='pb-2'>
                <Col sm={12}>
                    {project.description}
                </Col>
            </Row>
            <Row>
                {project.credits.map((credit, index) => {
                    return (
                        <Col key={index} sm={12} className=''>
                            <small className=''>{credit.title}:{" "}</small>
                            <small>
                                {credit.link && <a href={credit.link} target="_blank">{credit.name}</a>}
                                {!credit.link && <span className={credit.name.includes('studio ottagono') ? 'text-primary' : undefined}>{credit.name}</span>}
                            </small>
                        </Col>
                    )
                })}
            </Row>
        </Container >
    )
}

export default Caption;