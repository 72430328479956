import Container from 'react-bootstrap/esm/Container';
import MyForm from './Form';

const Contact = () => {
    return (
        <Container id="contact-container" className="col-xl-10 col-xxl-8 px-4 py-5">
            {/* <div className="container col-xl-10 col-xxl-8 px-4 py-5"> */}
            <div className="row align-items-center g-lg-5 py-5">
                <div className="col-lg-6 text-center text-lg-start">
                    <h1 className="display-4 fw-light lh-1 mb-3">Contattaci</h1>
                    <p className="col-lg-10 fs-4 fw-light text-muted">Invia un breve messsaggio. <br />Verrai ricontattato al più presto.</p>
                </div>
                <MyForm></MyForm>
            </div>
        </Container>
    )
}


export default Contact;