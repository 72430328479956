import React, { useState, useEffect } from 'react';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';

const ProjectCard = ({ project, handleClick }) => {

    const [hover, setHover] = useState(false);

    return (
        <Col lg={4} xs={6} className="project-items">
            <Card>
                <Card.Img
                    variant="top"
                    src={process.env.PUBLIC_URL + project.thumbnail}
                    onClick={() => handleClick(project)}
                    onMouseEnter={() => (setHover(true))}
                    onMouseLeave={() => (setHover(false))}
                    className={!hover ? 'gray-scale' : undefined}
                />
                <Card.Body className='text-start d-none d-sm-block'>
                    <Card.Text>{project.description}</Card.Text>
                </Card.Body>
                <Card.Footer className="text-muted text-end d-none d-sm-block">
                    <small>{project.tags.map((tag, index) => {
                        return (
                            <span key={index}> #{tag}</span>
                        )
                    })}</small>
                </Card.Footer>
            </Card>
        </Col>
    )
}

export default ProjectCard;