import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

const headlines = [
    {
        "title": "Energia.",
        "description": "L'utilizzo di energia è alla base di ogni attività umana. \
        La quantità e il tipo di energia che utiliziamo oggi determineranno il nostro domani. \
        ",
    },
    {
        "title": "Ambiente.",
        "description": "Un edificio sostenibile è un edificio in cui vivere bene, nel rispetto \
        dell'ambiente. Meno consumi, più comfort. ",
    },
    {
        "title": "Architettura.",
        "description": "Il nostro studio di architettura ti seguirà dal progetto fino alla realizzazione,\
        dall'idea al cantiere, con soluzioni studiate nel dettaglio per soddisfare i tuoi bisogni.",
    },
]

const Headlines = () => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    return (
        <Container fluid id="headlines-container" variant='dark' className="bg-dark px-4 py-5 kmb-5">
            <Carousel activeIndex={index} onSelect={handleSelect} interval={10000} className='mb-5'>
                {
                    headlines.map((item, index) => {
                        return (
                            <Carousel.Item key={index} style={{ "height": "250px" }}>
                                <Carousel.Caption className="text-start" style={{ "top": "0", "bottom": "auto" }}>
                                    <h1 className='fw-light lh-1 mb-3'>{item.title}</h1>
                                    <p className='fs-5 fw-light w-100'>{item.description}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                        )
                    })
                }
            </Carousel >
        </Container >
    );
}

export default Headlines;