import Navigator from './Navigator';
import Logo from './Logo';

function Header() {
    return (
        <header>
            <Navigator logo={<Logo />}></Navigator>
        </header>
    );
}

export default Header;