import React, { useState, useEffect } from 'react';

import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Project from './Project';
import ProjectCard from './ProjectCard';


const Projects = () => {

    const [projects, setProjects] = useState([]);
    const [currentProject, setCurrentProject] = useState(null);

    const handleClick = project => {
        setCurrentProject(project);
    }


    const getData = () => {
        fetch('data/projects.json', {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(function (response) {
            // console.log(response)
            return response.json();
        }).then(function (projectsJson) {
            // console.log(projectsJson);
            setProjects(projectsJson)
        });
    }

    useEffect(() => {
        getData();
    }, [])

    return (

        <Container id="projects-container" className="bg-light py-5" fluid >
            <Container className="p-5">
                <Row>
                    <h2 className='border-bottom'>Progetti</h2>
                </Row>
                <Row className="my-1 g-5">
                    {
                        projects.map((project, index) => {
                            return (
                                <ProjectCard
                                    key={index}
                                    project={project}
                                    handleClick={handleClick}
                                >
                                </ProjectCard>
                            )
                        })
                    }
                </Row>
                {/* cache placeholder */}
                <img className='d-none' src={process.env.PUBLIC_URL + `static/img/logo-gray-800x600-loading.png`}></img>
                <Project currentProject={currentProject} setCurrentProject={setCurrentProject}></Project>
            </Container>
        </Container>

    )
}

export default Projects;