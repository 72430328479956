// import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import Main from './components/Main';

// Importing the Bootstrap CSS
// import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/main.scss'

import Footer from './components/Footer';
import ErrorBoundary from './components/ErrorBoundary';
import CookieConsent from "react-cookie-consent";


import ReactGA from 'react-ga';
import Container from 'react-bootstrap/esm/Container';
ReactGA.initialize('G-9GZPQ3ZPLQ');
ReactGA.pageview(window.location.pathname + window.location.search);


const App = () => {
  return (
    <div className="App">
      <ErrorBoundary>
        <Header></Header>
        <Main></Main>
        <Footer></Footer>
        <CookieConsent
          containerClasses='bg-secondary footer mt-auto py-3 fixed-bottom text-white row bg-dark-gray '
          contentClasses='col-md-10'
          buttonText="Accetto"
          // buttonStyle={{ color: "#fffff", fontSize: "13px" }}
          disableStyles={true}
          disableButtonStyles={true}
          buttonClasses='btn btn-light'
          buttonWrapperClasses='col-md-2'

        >
          <Container className='d-flex align-items-center justify-content-center'>
            <span className=''>
              Noi e terze parti selezionate utilizziamo cookie o tecnologie simili per finalità tecniche e di misurazione come specificato nella <a href="https://www.iubenda.com/privacy-policy/64022847/cookie-policy" target='_blank' className='link-light'>cookie policy</a>.
            </span>
          </Container>
        </CookieConsent>
      </ErrorBoundary>
    </div>
  );
}

export default App;
