import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Instagram, Envelope } from 'react-bootstrap-icons'

const Footer = () => {

    const cookies = (w, d) => {
        var loader = function () {
            var s = d.createElement("script");
            var tag = d.getElementsByTagName("script")[0];
            s.src = "https://cdn.iubenda.com/iubenda.js";
            tag.parentNode.insertBefore(s, tag);
        };
        if (w.addEventListener) {
            w.addEventListener("load", loader, false);
        } else if (w.attachEvent) {
            w.attachEvent("onload", loader);
        } else { w.onload = loader; }
    };
    cookies(window, document);

    var _iub = _iub || [];
    _iub.csConfiguration = { "askConsentAtCookiePolicyUpdate": true, "enableLgpd": true, "floatingPreferencesButtonDisplay": "anchored-center-left", "perPurposeConsent": true, "siteId": 3193719, "whitelabel": false, "cookiePolicyId": 64022847, "lang": "it", "banner": { "acceptButtonDisplay": true, "closeButtonDisplay": false, "customizeButtonDisplay": true, "explicitWithdrawal": true, "listPurposes": true, "position": "float-top-center", "rejectButtonDisplay": true } };


    return (
        <Container className="bg-primary" fluid>
            <Container variant='dark' className="px-4 pt-5 pb-5 text-white" >
                <Row className='text-start lh-base mb-5'>
                    <Col>
                        {/* <div className="mb-2"><a href='mailto:info@ottagono.studio'>info@ottagono.studio</a></div> */}
                        <div className='mb-3 fw-light' style={{ 'fontSize': '1.6rem' }}>studio ottagono</div>
                        <div className="mb-3">info@ottagono.studio</div>
                        <div>
                            Via Garibaldi 22<br></br>
                            20021, Bollate (MI)<br></br>
                            <small>citofono 8</small><br></br>
                        </div>
                    </Col>
                </Row>
                <Row className="d-flex flex-wrap align-items-center py-2 border-top">
                    <Col xs={5} className='text-start'>
                        © 2021-{new Date().getFullYear()}
                    </Col>
                    {/* <Col className="text-start">
                    <a href="#hero-container" className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
                        <img
                            src="static/img/logo-white.svg"
                            width="30"
                            height="30"
                            className="d-inline-block align-top"
                            alt="studio ottagono logo"
                        />
                    </a>
                </Col> */}
                    <Col className='text-end'>

                        <ul className="ms-3 list-inline m-0">
                            {/* <li className="list-inline-item"> seguici</li> */}
                            <li className="list-inline-item">
                                <a className='text-white' href='https://www.instagram.com/studio_ottagono/' target='_blank'>
                                    <Instagram></Instagram>
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a className='text-white' href='mailto:info@ottagono.studio'>
                                    <Envelope></Envelope>
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a href="https://www.iubenda.com/privacy-policy/64022847/cookie-policy" className="iubenda-black iubenda-noiframe iubenda-embed iubenda-noiframe " title="Cookie Policy">Cookie Policy</a>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container >


            <script type="text/javascript" src="//cdn.iubenda.com/cs/iubenda_cs.js" charSet="UTF-8" async></script>


        </Container >
    )
}

export default Footer;