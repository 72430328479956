import { useState, useEffect, useCallback } from 'react'
import { GoogleMap, LoadScript, InfoWindowF, InfoWindow } from '@react-google-maps/api';
import { MarkerF } from '@react-google-maps/api';
import Container from 'react-bootstrap/Container';

const containerStyle = {
    width: '100%',
    height: '400px'
};

// https://mapstyle.withgoogle.com/
const mapStyles =
    [
        // {
        //     "featureType": "administrative",
        //     "elementType": "geometry",
        //     "stylers": [
        //         {
        //             "visibility": "off"
        //         }
        //     ]
        // },
        // {
        //     "featureType": "administrative.land_parcel",
        //     "elementType": "labels",
        //     "stylers": [
        //         {
        //             "visibility": "off"
        //         }
        //     ]
        // },
        {
            "featureType": "poi",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        // {
        //     "featureType": "poi",
        //     "elementType": "labels.text",
        //     "stylers": [
        //         {
        //             "visibility": "off"
        //         }
        //     ]
        // },
        // {
        //     "featureType": "road",
        //     "elementType": "labels.icon",
        //     "stylers": [
        //         {
        //             "visibility": "off"
        //         }
        //     ]
        // },
        // {
        //     "featureType": "road.local",
        //     "elementType": "labels",
        //     "stylers": [
        //         {
        //             "visibility": "off"
        //         }
        //     ]
        // },
        // {
        //     "featureType": "transit",
        //     "stylers": [
        //         {
        //             "visibility": "off"
        //         }
        //     ]
        // }
    ]

const center = {
    lat: 45.546332,
    lng: 9.1177380,
};

const API_KEY = "AIzaSyDo7_J2oiQineGVZfFYndwGKc1yWU2rmCQ"

const Tooltip = () => {
    return (
        <>
            <p><strong>studio ottagono</strong></p>
            <p className='lh-sm'>
                via Garibaldi 22<br></br>
                20021 Bollate(MI)<br></br>
                <span className='mt-1'>citofono 8</span>
            </p>
            <span><a target='_blank' href='https://www.google.com/maps/place/Via+Giuseppe+Garibaldi,+22,+20021+Bollate+MI/@45.5463864,9.1171587,19z/data=!3m1!4b1!4m6!3m5!1s0x4786bffd8fea8d95:0x9d86dd66e670ab6a!8m2!3d45.5463864!4d9.1177059!16s%2Fg%2F11csp3w5_v'>
                Google Maps
            </a>
            </span>
        </>
    )
}

const Map = () => {

    const [showInfoWindow, setShowInfoWindow] = useState(false);

    return (
        <Container id="map-container" className="px-0" fluid>
            <LoadScript googleMapsApiKey={API_KEY}>
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={15}
                    // clickableIcons={false}
                    options={{ styles: mapStyles }}
                >
                    { /* Child components, such as markers, info windows, etc. */}
                    <>
                        <MarkerF
                            key={'my-marker'}
                            position={center}
                            clickable={true}
                            onClick={() => { setShowInfoWindow(true) }}
                        >
                            {/* https://tomchentw.github.io/react-google-maps/#!/InfoWindow */}
                            {showInfoWindow && <InfoWindowF key={'my-infowindow'} onCloseClick={() => { setShowInfoWindow(false) }}>
                                <>
                                    <Tooltip key={'my-tooltip'}></Tooltip>
                                </>
                            </InfoWindowF>}
                        </MarkerF>
                    </>
                </GoogleMap>
            </LoadScript>
        </Container>
    )
}

export default Map;