import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Envelope, Instagram, ChatLeftText } from 'react-bootstrap-icons';

const Hero = () => {
    return (
        <Container id="hero-container" className="text-center px-4 py-5">
            <Row className="py-lg-5 mt-5 flex-lg-row-reverse align-items-center" style={{ "height": "300px" }}>
                {/* <Col lg="6">
                    <img src="static/img/progetto.png" className="d-block mx-lg-auto" alt="" width={300} loading="lazy" />
                </Col> */}
                <Col>
                    <h1 className='display-5 fw-light lh-1'>studio ottagono</h1>
                    <p className='lead text-muted'>energia, ambiente, architettura</p>
                    {/* <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                        <Button href="#contact-container" variant="outline-primary" size="sm"><ChatLeftText className='align-center' /></Button>
                        <Button href="https://www.instagram.com/studio_ottagono/" target="_blank" variant="outline-primary" size="sm"><Instagram className='align-center' /></Button>
                    </div> */}
                </Col>
            </Row>
        </Container>

    )
}

export default Hero;