import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import Logo from './Logo'


const Header = () => {
    return (
        <Navbar bg="primary" variant="dark" expand="lg" fixed="top">
            <Container>
                <Logo />
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav id="nav-navigator" className="me-auto">
                        <Nav.Link href="#features-container">Studio</Nav.Link>
                        <Nav.Link href="#projects-container">Progetti</Nav.Link>
                        <Nav.Link href="#contact-container">Contattaci</Nav.Link>
                        <Nav.Link href="#map-container">Mappa</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;