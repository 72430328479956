import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer'
import { HeartbreakFill } from 'react-bootstrap-icons';
import { HeartFill } from 'react-bootstrap-icons';

const Notify = ({ show, setShow, success }) => {

    return (
        <ToastContainer className="p-3" position="top-end" containerPosition="fixed">
            <Toast show={show} onClose={() => setShow(false)} bg={success ? "success" : "danger"} delay={3000} autohide>
                <Toast.Body className='text-white'>
                    {!success && <span><HeartbreakFill /> ops..riprova</span>}
                    {success && <span><HeartFill /> messaggio inviato!</span>}
                </Toast.Body>
            </Toast>
        </ToastContainer >
    );
}

export default Notify;