// import ReCAPTCHA from 'react-google-recaptcha'
import { useState } from 'react';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Privacy from '../Privacy';
import Notify from './Notify';
// {/* <ReCAPTCHA sitekey="6Lekb84kAAAAAOVmv-vkkeQk45tE6cssNw5qRbXP" onChange={onChange} /> */}

const MAX_CHAR = 200;

const MyForm = () => {

    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [showPrivacy, setShowPrivacy] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [success, setSuccess] = useState(null); // null or false(error) or true(ok)

    // iubenda
    const privacy = (w, d) => {
        var loader = function () {
            var s = d.createElement("script");
            var tag = d.getElementsByTagName("script")[0];
            s.src = "https://cdn.iubenda.com/iubenda.js";
            tag.parentNode.insertBefore(s, tag);
        };
        if (w.addEventListener) {
            w.addEventListener("load", loader, false);
        } else if (w.attachEvent) {
            w.attachEvent("onload", loader);
        } else { w.onload = loader; }
    }
    privacy(window, document);
    // iubenda

    const handleSubmit = e => {
        e.preventDefault();
        setLoading(true);

        const elements = e.target.elements;

        let formData = new FormData();
        formData.append('name', elements.name.value);
        formData.append('phone', elements.phone.value);
        formData.append('email', elements.email.value);
        formData.append('text', elements.text.value);


        fetch('https://api.ottagono.studio/api/contacts', { //TODO: make this parametric
            // headers: {
            //     'Content-Type': 'multipart/form-data',
            //     // 'Accept': 'application/json',
            // },
            // mode: 'no-cors',
            method: 'POST',
            body: formData,
            // body: JSON.stringify({
            //     'name': elements.name.value,
            //     'phone': elements.phone.value,
            //     'email': elements.email.value,
            //     'text': elements.text.value,
            // })
        }).then(response => {
            if (!response.ok) {
                console.log(response)
                throw new Error()
            }
            setSuccess(true);
        }).catch(error => {
            console.log(error);
            setSuccess(false);
        }).finally(() => {
            setLoading(false);
            setShowNotification(true);
        });
    }

    const handlePrivacyClick = e => {
        e.preventDefault();
        setShowPrivacy(true);
    }

    return (
        <div className="col-md-10 mx-auto col-lg-6">
            <fieldset disabled={loading | showNotification | success}>
                <Form className="p-4 p-md-5 border rounded-3 bg-light" onSubmit={handleSubmit}>
                    <FloatingLabel controlId="name" label="nome*" className="mb-3">
                        <Form.Control type="text" placeholder="name" required />
                    </FloatingLabel>
                    <FloatingLabel controlId="phone" label="telefono" className="mb-3">
                        <Form.Control type="number" min="0" placeholder="phone" />
                    </FloatingLabel>
                    <FloatingLabel controlId="email" label="email*" className="mb-3">
                        <Form.Control type="email" placeholder="email" required />
                    </FloatingLabel>
                    <FloatingLabel controlId="text" label="messaggio*">
                        <Form.Control as="textarea" placeholder="messaggio" required onChange={e => setCount(e.target.value.length)} maxLength={MAX_CHAR} style={{ height: '160px' }} />
                    </FloatingLabel>
                    <p className='w-100 text-end'><small className="text-muted mb-5">{count}/{MAX_CHAR}</small></p>
                    <Button variant="primary" size="lg" className="w-100" type="submit">
                        {!loading && !success && "Invia"}
                        {!loading && success && "Messaggio inviato!"}
                        {loading &&
                            <Spinner as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true">
                            </Spinner>}
                    </Button>
                    <hr className="my-4" />
                    <small className="text-muted">Cliccando Invia, accetti questi <span></span>
                        {/* <a href="#" onClick={handlePrivacyClick}>termini</a>. */}
                        <a href="https://www.iubenda.com/privacy-policy/64022847" target='_blank' className='iubenda-black iubenda-noiframe iubenda-embed iubenda-noiframe' title="Privacy Policy ">termini</a>
                    </small>
                    <Privacy show={showPrivacy} setShow={setShowPrivacy}></Privacy>
                </Form>
            </fieldset>
            <Notify success={success} show={showNotification} setShow={setShowNotification}></Notify>
        </div >
    )
}

export default MyForm;