import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { HouseGear, Lightbulb, PencilSquare } from 'react-bootstrap-icons';

const features = [
    {
        "title": "Architettura",
        // "description": "progetto bello ma che dico bello.. normale, anche se poi andando a vedere c'era anche quello si",
        "services": [
            "studi di fattibilità",
            "progettazione architettonica",
            "pratiche edilizie",
            "direzione lavori",
        ],
        "icon": <HouseGear size={"30"} />
    },
    {
        "title": "Energia",
        // "description": "progetto bello ma che dico bello.. normale, anche se poi andando a vedere c'era anche quello si",
        "services": [
            "diagnosi energetiche",
            "relazioni tecniche legge 10",
            "certificazioni energetiche",
            "progettazione impiantistica",
        ],
        "icon": <Lightbulb size={"30"} />
    },
    {
        "title": "Altro",
        // "description": "progetto bello ma che dico bello.. normale, anche se poi andando a vedere c'era anche quello si",
        "services": [
            "conformità urbanistica",
            "analisi preventivi",
            "computi metrici",
            "asseverazioni",
        ],
        "icon": <PencilSquare size={"30"} />
    },
]

const Features = () => {
    return (

        <Container id="features-container" className="px-4 py-5">
            <Container className="p-5">
                <h2 className='p-2 fw-ligth border-bottom text-center'>Chi siamo</h2>
                <Row className="py-lg-5 mt-2">
                    <Col lg="8" md="8" className="mx-auto">
                        <p className='lead text-muted'>
                            studio ottagono è uno studio di architettura con esperienza decennale nella ristrutturazione del patrimonio edilizio
                            esistente, con una forte specializzazione nella riqualificazione energeticamente consapevole degli edifici.
                            <br></br><br></br>
                            Perchè "ottagono"?<br></br>Questa figura geometrica rappresenta simbolicamente la sintesi tra cerchio e quadrato,
                            tra cielo e terra; allo stesso modo un buon progetto rappresenta l'unione armonica di diversi elementi, concreti e astratti,
                            lontani e vicini, globali e locali.
                        </p>
                    </Col>
                </Row>
                {/* <Row className='g-4 text-start'>
                {
                    features.map((feature, index) => {
                        return (
                            // <Col key={index} className="feature" sm={12} md={4}>
                            //     <div className='mb-2'>{feature.icon}</div>
                            //     <h3 className='fs-2'>{feature.title}</h3>
                            //     <p className=''>{feature.description}</p>
                            // </Col>
                            <Col key={index} className="feature align-items-start" sm={12} md={4}>
                                <div class="icon-square d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                                    {feature.icon} <div class="mb-0 px-2 h3">{feature.title}</div>
                                </div>
                                <div className='mt-2'>
                                    <p class="muted">{feature.description}</p>
                                    <ul>
                                        {feature.services.map((service, index) => {
                                            return (
                                                <li>{service}</li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </Col>
                        )
                    })
                }
            </Row> */}





            </Container>
        </Container >


    )
};

export default Features;