import Navbar from 'react-bootstrap/Navbar';


const Logo = () => {
    return (
        <Navbar.Brand href="#hero-container">
            <img
                src="static/img/logo-white.svg"
                width="30"
                height="30"
                className="d-inline-block align-top"
                alt="studio ottagono logo"
            />
        </Navbar.Brand>
    )
}

export default Logo;