import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Modal from 'react-bootstrap/Modal';

import Caption from './Caption';

const Placeholder = () => {
    // TODO: center logo!!!
    return (
        <Carousel variant="dark" controls={false} indicators={false}>
            <Carousel.Item key={"thumbnail"}>
                <img className='d-block w-100' src={process.env.PUBLIC_URL + `static/img/logo-gray-800x600-loading.png`}></img>
            </Carousel.Item>
        </Carousel>
    )
}


const Project = ({ currentProject, setCurrentProject }) => {

    const [loaded, setLoaded] = useState(false);

    const handleOnLoad = index => {
        //only first (the current) can say: ok, loaded
        if (index === 0) setLoaded(true);
    }

    const handleOnHide = () => {
        setCurrentProject(null);
        setTimeout(() => {
            setLoaded(false); //delay to avoid flickering
        }, 500);
    }

    return (
        <Modal
            size="lg"
            show={!!currentProject}
            onHide={handleOnHide} //reset project
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
                {/* <Modal.Title></Modal.Title> */}
            </Modal.Header>
            <Modal.Body className='p-0'>
                {!loaded && <Placeholder></Placeholder>}
                <Carousel variant="dark" controls={loaded} indicators={loaded}>
                    {
                        currentProject?.pics && currentProject.pics.map((pic, index) => {
                            return (
                                <Carousel.Item key={index}>
                                    <img
                                        className={!loaded ? "d-block w-100 d-none" : "d-block w-100"}
                                        src={process.env.PUBLIC_URL + `static/projects/${currentProject.name}/${pic.src}`}
                                        onLoad={() => { handleOnLoad(index) }}
                                    />
                                </Carousel.Item>
                            )
                        })
                    }
                </Carousel>
            </Modal.Body >
            <Modal.Footer>
                {currentProject && <Caption project={currentProject}></Caption>}
            </Modal.Footer>
        </Modal >
    );
}

export default Project;