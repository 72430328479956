import Container from 'react-bootstrap/Container';
import Projects from './Projects/Projects'
import Features from './Features/Features';
import Hero from './Hero';
import Contact from './Form/Contact';
import Map from './Map';
import Headlines from './Headlines/Headlines';



const Main = () => {

    return (
        <main>
            <Container fluid
                data-bs-spy="scroll" //crollspy
                data-bs-target="#nav-navigator"
                // data-bs-smooth-scroll="true"
                tabIndex="0"
                className='px-0'
            >
                <Hero></Hero>
                <Headlines></Headlines>
                <Features></Features>
                <Projects></Projects>
                <Contact></Contact>
                <Map></Map>
            </Container>
        </main>
    );
}

export default Main;